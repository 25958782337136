<template>
  <div>
    <v-toolbar flat
      ><v-spacer></v-spacer><SearchField v-model="search"></SearchField
    ></v-toolbar>
    <v-list>
      <v-data-iterator :items="itemsFiltered">
        <template v-slot:default="{ items }">
          <ChangeItem
            v-for="(item, index) in items"
            :key="'change' + index"
            :value="item"
          >
          </ChangeItem>
        </template>
      </v-data-iterator>
    </v-list>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ChangeItem from "@/components/ChangeItem.vue";
import SearchField from "@/components/SearchField.vue";

export default defineComponent({
  name: "ChangeLog",
  components: { ChangeItem, SearchField },
  props: {
    id: { type: String },
  },
  data() {
    return {
      items: [],
      search: null,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items
        .filter(
          (el) =>
            !this.search ||
            el.type.description
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            (el.description &&
              el.description.toLowerCase().includes(this.search.toLowerCase()))
        )
        .sort((a, b) => {
          const value_a = a.date + a.time;
          const value_b = b.date + b.time;
          return value_b.localeCompare(value_a);
        });
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.id) {
        return;
      }
      this.items = await this.apiGet({
        resource: "automation/changelog",
        id: this.id,
      });
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
